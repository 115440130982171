@import '../../variables';

.rounded-button {
  display: flex;
  justify-content: center;
  flex-shrink: 1;
  font-size: 16px;
  font-family: $font-family-normal;
  color: $xpektor-cyan;
  border: 1px solid $xpektor-cyan;
  background: none;
  border-radius: 22px;
  padding: 9px 27px;
  margin: 0;
  white-space: nowrap;
  transition: 0.125s;

  &:hover {
    background-color: white;
    border-color: white;
    color: black;
  }
}

.rounded-button-fill-cyan {
  color: black;
  background-color: $xpektor-cyan;
}

.rounded-button-fill-blue {
  color: white;
  background-color: $xpektor-blue;
  border-color: $xpektor-blue;
}
