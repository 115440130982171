@import '../../../../variables';

.system-admin-customers-wrapper {
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  gap: 12px;

  .nav-row {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }

  .customer-table-container {
    flex-direction: column;
    align-items: center;
    max-width: 1500px;

    .customer-table-header {
      flex-direction: row;
    }

    .customer-list {
      flex-direction: column;
      gap: 12px;
      width: 100%;

      .customer-entry {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
        height: 180px;
        padding: 12px 24px;
        gap: 6px;

        p {
          font-family: $font-family-normal;
          color: black;
          margin: 0;
          font-size: 14px;
        }

        .content-column {
          flex-direction: column;
          justify-content: space-between;
          border-right: 1px solid $xpektor-light-gray;
          flex: 1;

          .data-row {
            display: grid;
            grid-template-columns: 300px 300px 1fr;

            .data-column {
              flex-direction: column;
              padding: auto 12px auto 0;
              border-right: 1px solid $xpektor-light-gray;
              margin-right: 12px;

              &:last-child {
                border: none;
              }

              p {
                font-size: 12px;
              }
            }
          }

          .gap-row {
            flex-direction: row;
            align-items: center;
            gap: 12px;
          }

          .title-row {
            flex-direction: row;
            align-items: center;
            height: 28px;

            .title {
              font-size: 22px;
              font-weight: 600;
              line-height: 1;
            }

            .tag {
              flex-direction: row;
              align-items: center;
              border: 1px solid $xpektor-light-gray;
              border-radius: 6px;
              background-color: $xpektor-light-blue;
              padding: 3px 6px;

              p {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }

        .logo-column {
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 20%;
          gap: 24px;
          padding: 0 24px;

          .logo {
            height: 50px;
            margin: 0 auto;
          }

          .missing-logo {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 200px;
            p {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
