@import '../../../variables';

.bank-modal-wrapper {
  .modal-dialog {
    max-width: 700px;

    .modal-content {
      border-radius: 8px;

      .modal-title {
        font-family: $font-family-normal;
        margin-left: 20px;
      }

      .modal-body {
        padding-top: 0;

        .bank-connected-view {
          padding: 12px;

          .bank-session-content {
            display: flex;
            flex-direction: column;
            border: 1px solid;
            border-radius: 6px;
            padding: 24px;
            margin: 12px 0;

            p {
              color: black;
              padding: 0;
              margin: 0;
            }
            .bank-session-status {
              p {
                font-family: $font-family-head;
                font-size: 22px;
              }
            }
            p {
              font-size: 14px;
              font-family: $font-family-normal;
            }
            .session-error-text {
              margin-top: 12px;
              font-size: 12px;
            }
          }
          .session-ok {
            border-color: green;
            background-color: rgb(219, 255, 219);
          }
          .session-error {
            border-color: darkred;
            background-color: rgb(255, 219, 216);
          }
          .xpektor-button {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
