@import '../../../variables';
.faq-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 16px;
  flex: 1;

  .faq-content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .faq-title {
    font-size: 36px;
    font-family: $font-family-normal;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 18px;
    color: black;
    font-weight: 600;
    font-style: normal;
    margin-right: auto;
    border-bottom: 1px solid $xpektor-light-gray;
  }
  .faq-nav-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .tab-nav-wrapper {
      margin-right: auto;
      button {
        width: auto;
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }
}
