@import '../../variables';

.xpektor-date-picker {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 12px 16px 3px 16px;

  .date-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 0 8px 0 8px;

    .title {
      font-family: $font-family-head;
      font-size: 20px;
      font-weight: bold;
      color: black;
      margin: 0;
    }

    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      color: black;
      margin: 0;
      font-size: 15px;
      padding: 4px 8px;
      border: 1px solid $xpektor-light-gray;
      border-radius: 8px;

      &:hover {
        cursor: default;
      }
    }
  }
  .calendar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .calendar-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .xpektor-dropdown {
        p {
          line-height: 1;
        }
      }

      .month-arrow {
        color: green;
        border: none;
        background: none;

        &:disabled {
          color: gray;
        }
      }
    }
    .weekday-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 4px;
      border-bottom: 1px solid $xpektor-light-gray;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-normal;
        font-size: 14px;
        color: black;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
    .calendar-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 6px;
      margin-top: 3px;

      .calendar-day-button {
        display: flex;
        height: 28.85px;
        justify-content: center;
        align-items: center;
        font-family: $font-family-normal;
        font-size: 14px;
        color: black;
        background: none;
        border: none;
        border-radius: 50%;
        padding: 0;
        margin: 0;
      }

      .disabled {
        color: gray;
      }

      .placeholder {
        color: transparent;
      }

      .selected {
        color: white;
        background-color: $xpektor-blue;
      }
    }
  }
}

.xpektor-date-range-picker {
  .calendars {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid $xpektor-light-gray;
  }
  .sub-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 16px;

    .xpektor-button {
      height: 70%;
    }
  }
}
