@import '../../variables';

.labeled-time-mask {
  display: flex;
  flex-direction: row;
  align-items: center;
  mask-image: linear-gradient(black, black);

  .labeled-time-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      padding: 0;
      margin-top: auto;
      margin-bottom: auto;
      color: var(--Grey-dark, #425466);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
    }

    .label {
      font-weight: 400;
      margin-right: 5px;
    }

    .value {
      font-weight: 600;
    }
  }

  .anim-x {
    transform: translateX(-100%);
    animation-name: slideX;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .anim-y {
    transform: translateY(-100%);
    animation-name: slideY;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .divider {
    padding-right: 10px;
    border-right: 2px solid darkgray;
  }

  @keyframes slideX {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideY {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }
}
