.xpektor-clickable-logo {
  height: 40px;
  width: 150px;
  font-size: 0;
  background-image: url('../../Images/xpektor_logo_new.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(1);
}

.xpektor-clickable-logo-dark {
  filter: none;
}
