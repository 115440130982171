@import '../../../variables';

.client-auth-link-modal-wrapper {
  .modal-title {
    font-family: $font-family-normal;
    font-size: 18px;
  }
  .auth-link-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 12px 0;

    p {
      margin: 0;
    }

    .auth-link-missing {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: black;
        font-size: 14px;
        font-family: $font-family-normal;
        margin-bottom: 36px;
        text-align: center;
      }
    }

    .xpektor-button {
      width: 200px;
      button {
        font-family: $font-family-head;
        font-weight: 600;
      }
    }

    .auth-state-display {
      display: flex;
      flex-direction: column;
      width: 100%;
      .auth-state-text {
        font-family: $font-family-normal;
        font-size: 14px;
        color: black;
        margin-left: 6px;
      }

      .auth-link-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 40px;
        margin-top: 36px;

        .xpektor-button {
          width: 140px;
          height: 40px;

          button {
            &:disabled {
              background-color: rgb(131, 168, 255);
            }
          }
        }

        .copied-check-icon {
          font-size: 16px;
        }
      }
    }
  }
}
