@import '../../variables';

.report-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-family: $font-family-normal;
    color: black;
    margin: 0;
    padding: 0;
  }

  .report-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .report-client-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 9px;
      }
    }

    .report-title-column {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .report-document-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      p {
        font-size: 9px;
      }
    }
  }

  .report-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .report-divider {
      height: 2px;
      width: 100%;
      background-color: black;
      margin-bottom: 4px;
    }

    .report-table-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 2px 6px;

      .report-bold {
        font-weight: 700;
      }

      p {
        font-size: 10px;
      }

      .title-section {
        display: flex;
        flex-direction: row;
        width: 40%;

        .report-account-number {
          color: $xpektor-blue;
          margin-right: 8px;
        }
      }

      .values-section {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        justify-content: flex-end;
        width: 70%;

        p {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .tr-bottom-space {
      margin-bottom: 12px;
    }

    .report-list-section {
      min-height: 16px;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
    }
  }
}
