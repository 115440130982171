@import '../../../../../../variables';

.transaction-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-family: $font-family-normal;
    margin: 0;
    padding: 0;
    color: black;
  }

  .transaction-table-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 600px;
    border-radius: 6px;
    background-color: $xpektor-light-blue;
    overflow-y: scroll;

    .load-fail {
      margin: auto;
      font-family: $font-family-head;
      font-weight: 500;
    }

    .transaction-date {
      margin-left: 12px;
      margin-top: 12px;
      color: var(--747-a-80, #747a80);
      font-size: 14px;
    }

    .transaction-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 64px;
      height: auto;
      padding: 6px;
      width: 97%;
      margin: 6px 6px;
      border: none;
      border-radius: 12px;
      background-color: white;
      transition: 0.125s;

      &:hover {
        box-shadow: 2px 2px 8px lightblue;
        border-color: white;
        transform: scale(1.0075);
      }

      .transaction-item-logo {
        margin-right: 6px;
        border-radius: 12px;
        height: 52px;
        border: 1px solid
          var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
      }

      .transaction-item-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 6px;

        .transaction-name {
          font-weight: 600;
          font-size: 16px;
        }

        .transaction-remittance {
          font-size: 14px;
          text-align: left;
        }
      }

      .transaction-item-money {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: 12px;
        gap: 6px;
        align-items: center;

        p {
          font-size: 14px;
        }
      }
    }
  }
  .transaction-table-footer {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 100%;
    margin-left: auto;
    padding-top: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $xpektor-light-blue;
  }
}
