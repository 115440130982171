@import '../../variables';

.balance-bar {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-family: $font-family-normal;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
  }
  .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .bar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    width: 100%;

    .fill-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border: 1px solid rgb(204, 204, 204);
      transition: width;
      transition-duration: 0.5s;
      min-width: 6px;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      .percentage-text {
        font-size: 12px;
        margin: auto;
      }
    }
  }
  .details-content {
    display: flex;
    flex-direction: row;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    height: 100%;

    .details-column {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 6px 12px;

      p {
        font-size: 12px;
      }

      .details-sum {
        border-top: 1px solid black;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
}
