@import '../../../../variables';

.service-status-message-table {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  p {
    font-family: $font-family-normal;
    color: black;
    margin: 0;
    padding: 0;
  }
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .table-title {
      font-size: 20px;
      font-weight: 600;
      padding-left: 12px;
    }
  }

  .title-underline {
    display: flex;
    background-image: linear-gradient(
      to right,
      $xpektor-blue,
      rgba(255, 255, 255, 0)
    );
    height: 1px;
    width: 800px;
    min-width: 0;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 12px;
    .no-messages {
      font-size: 16px;
      font-style: italic;
      padding-left: 12px;
    }
    .service-status-message-item {
      display: flex;
      flex-direction: row;
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;
      padding: 12px;
      min-height: 128px;
      .message-text-column {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 6px;
        border-right: 1px solid $xpektor-light-gray;
        padding-right: 12px;
        .message-item-title-row {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          .message-item-title {
            font-size: 16px;
            font-weight: 600;
          }
          .message-item-icon {
            font-size: 16px;
          }
        }

        .message-item-text {
          font-size: 14px;
        }
      }
      .message-actions-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
      }
    }
  }
}
