@import '../../variables';

.xpektor-home-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px;
  z-index: 10;

  .top-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    .xpektor-clickable-logo {
      align-self: center;
    }

    .main-nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      gap: 36px;
      padding-top: 6px;

      .main-nav-button {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 1px;
        padding: 3px 0;

        a {
          text-decoration: none;
          color: $xpektor-cyan;
          font-family: $font-family-normal;
          font-weight: 200;
          white-space: nowrap;
          font-size: 16px;
        }

        .underline {
          display: flex;
          height: 1px;
          background-color: $xpektor-cyan;
          width: 0;
          transition: 0.125s;
        }

        &:hover {
          cursor: pointer;
          a {
            color: white;
          }
          .underline {
            width: 100%;
            background-color: white;
          }
        }
      }
    }

    .action-nav {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 24px;
    }

    .mobile-icon {
      display: none;
      font-size: 32px;
      color: white;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobile-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    a {
      text-decoration: none;
      color: $xpektor-cyan;
      padding: 6px 0;
      font-size: 20px;
      line-height: 1;

      &:hover {
        color: white;
      }
    }

    .login-button {
      margin-top: 48px;
    }
  }

  @media screen and (max-width: 1200px) {
    .top-row {
      .main-nav {
        gap: 5%;
      }
      .action-nav {
        .rounded-button {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .main-nav {
        display: none;
      }
      .action-nav {
        display: none;
      }
      .mobile-icon {
        display: flex;
      }
    }
    .mobile-menu {
      display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    padding: 24px;
  }
}

.home-nav-back-fill {
  background: none;

  @media screen and (max-width: 900px) {
    position: fixed;
    background-image: linear-gradient(#000b4f, #010024);
  }
}
