@import '../../../variables';

.reset-password-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  min-width: 300px;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient($background-blue, $background-dark-blue);

  p {
    padding: 0;
    margin: 0;
    color: white;
  }

  .reset-password-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20%;
    width: 100%;
  }

  .reset-password-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 50%;

    .reset-title {
      display: flex;
      align-items: center;
      margin-bottom: 64px;

      p {
        font-family: $font-family-normal;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .reset-password-form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 60%;

      .xpektor-form {
        max-width: 500px;
        label {
          color: rgba(255, 255, 255, 0.7);
        }
        input {
          color: white;
        }
        textarea {
          color: white;
        }

        .form-input-container {
          width: 100%;
          input {
            border: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
        .input-focus {
          label {
            color: white;
          }
          input {
            background-color: rgba(0, 0, 0, 0.25);
          }
        }

        .xpektor-form-submit-button {
          border-radius: 24px;
          margin-top: 24px;
          background-color: $xpektor-cyan;
          color: black;

          &:hover {
            background-color: white;
          }
        }
      }

      .invalid-text {
        text-align: center;
        margin-top: 64px;
        padding: 12px 24px;
        color: darkred;
        border: 1px solid darkred;
        background-color: rgb(255, 240, 240);
        border-radius: 6px;
        font-size: 16px;
      }
      .completed-text {
        text-align: center;
        margin-top: 64px;
        padding: 12px 24px;
        color: darkgreen;
        border: 1px solid darkgreen;
        background-color: rgb(240, 255, 241);
        border-radius: 6px;
        font-size: 16px;
      }
      .xpektor-button {
        margin-top: 48px;
      }
    }
  }
}
