@import '../../variables';

.hover-info-highlight {
  color: $xpektor-blue !important;
  cursor: default;
}

.hover-info-tooltip {
  max-width: 400px;
  background-image: linear-gradient(
    135deg,
    $xpektor-light-blue,
    white
  ) !important;
  border: 1px solid $xpektor-light-gray;
  box-shadow: 2px 2px 4px rgb(177, 199, 206) !important;
  border-radius: 6px;
  opacity: 1 !important;
  z-index: 3;

  p {
    font-size: 14px;
    color: black;
  }
}
