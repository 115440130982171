@import '../../../../../../variables';

.filter-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin: 0 6px;
  .filter-dropdown-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: none;
    text-align: left;
    padding: 6px 9px;
    margin: 3px 0 0 0;
    border: none;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;

    &:hover {
      background-color: $xpektor-light-blue;
    }
    p {
      color: black;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    .dropdown-icon {
      font-size: 10px;
    }
  }
  .filter-content {
    padding: 0;

    .toggle-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 36px;
      width: 96%;
      border: none;
      border-radius: 6px;
      margin: 0;
      padding: 0 6px;
      background-color: white;
      transition: background-color 0.125s;

      &:hover {
        background-color: $xpektor-light-blue;
      }

      .toggle-icon {
        font-size: 12px;
        color: gray;
        padding: 0 3px;
      }

      p {
        font-family: $font-family-head;
        font-size: 14px;
        color: black;
        margin: 0;
        padding: 6px;
        line-height: normal;
        text-align: left;
      }

      .item-count {
        margin-left: auto;
        font-family: $font-family-normal;
        font-size: 12px;
      }
    }

    .toggle-active {
      border-width: 1px;
      border-color: $xpektor-blue;
      .toggle-icon {
        color: $xpektor-blue;
      }
    }
  }
}

.clear-filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  padding: 3px 9px;
  margin: 6px 6px;
  background: none;
  border: 1px solid $xpektor-blue;
  border-radius: 6px;
  &:enabled:hover {
    background-color: $xpektor-light-blue;
  }
  .clear-filter-icon {
    font-size: 12px;
    color: $xpektor-blue;
  }
  p {
    font-family: $font-family-normal;
    font-size: 14px;
    color: $xpektor-blue;
    margin: 0;
    padding: 0;
  }

  &:disabled {
    border-color: $xpektor-light-gray;
    .clear-filter-icon {
      color: gray;
    }
    p {
      color: gray;
    }
  }
}
