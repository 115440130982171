@import '../../../variables';

.process-card {
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background-color: white;
  border-radius: 12px;
  width: 360px;

  img {
    margin: 12px 24px 24px 24px;
    width: 120px;
    aspect-ratio: 1;
  }

  .card-title {
    color: black;
    font-size: 26px;
    font-weight: 500;
    font-family: $font-family-normal;
  }

  .card-text {
    font-size: 16px;
    text-align: center;
    margin-bottom: 36px;
    font-family: $font-family-normal;
    line-height: 1.6;
  }

  @media screen and (max-width: 1200px) {
    .card-tag {
      margin-bottom: 12px;
    }
  }

  @media screen and (max-width: 900px) {
    padding: 16px;
    max-width: 300px;
    min-width: 250px;

    img {
      margin: 6px 12px 12px 12px;
    }
  }
}
