@import '../../../variables';

.faq-question-wrapper {
  display: flex;
  flex-direction: column;
  p {
    color: black;
    font-family: $font-family-normal;
    padding: 12px;
  }
  .faq-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $xpektor-light-gray;
    border-radius: 12px;
    background-color: white;
    align-items: center;
    z-index: 1;
    padding-right: 18px;
    &:hover {
      background-image: linear-gradient(
        to right,
        $xpektor-light-blue,
        rgba(1, 1, 1, 0)
      );
    }
    p {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
  }

  .faq-answer-container {
    display: flex;
    opacity: 1;
    border-right: 1px solid $xpektor-light-gray;
    border-left: 1px solid $xpektor-light-gray;
    border-bottom: 1px solid $xpektor-light-gray;
    border-top: none;
    border-radius: 12px;
    white-space: pre-wrap;
    p {
      font-size: 14px;
      padding: 12px 24px;
    }
  }
  .answer-hidden {
    opacity: 0;
    height: 0;
  }
}
