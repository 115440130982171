.client-settings-container {
  .client-settings-header {
    display: flex;
    height: 70px;
    align-items: flex-end;
    justify-content: flex-end;
    .xpektor-button {
      width: 200px;
      height: 40px;
    }
  }
}
