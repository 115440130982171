@import '../../../../variables';

.sidebar-button-wrapper {
  width: 100%;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 48px;
    border: 0;
    background-color: $background-dark-blue;
    padding-right: 0;

    .button-icon {
      color: white;
      width: 25%;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      .button-icon {
        color: white;
      }
      p {
        color: white;
      }
    }

    p {
      color: white;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
      padding: 0;
    }
  }
  .active-bar {
    background-color: white;
    .button-icon {
      color: black;
    }
    p {
      color: black;
    }
  }
}
