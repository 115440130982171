@import '../../../variables';

.bank-type-modal {
  .modal-header {
    border: none;
  }
  .modal-body {
    padding: 0 24px 24px 24px;

    p {
      font-weight: $font-family-normal;
      color: black;
    }

    .bank-type-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 64px;

      img {
        width: 200px;
      }
      .bank-name {
        margin-bottom: 6px;
        font-size: 16px;
      }
    }
    .bank-type-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 200px;

      .action-title {
        font-size: 16px;
        font-weight: 600;
      }

      .bank-types {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        gap: 24px;
        padding: 24px 0;

        button {
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          background: none;
          width: 40%;
          height: 100px;
          font-size: 18px;

          &:hover {
            border-color: $xpektor-blue;
            background-color: $xpektor-light-blue;
          }
        }
      }
    }
  }
}
