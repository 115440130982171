@import '../../../variables';

.kyc-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-dark-blue;
  min-height: 100vh;

  div {
    display: flex;
  }

  .kyc-modal {
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    background-color: white;
    border-radius: 12px;
    margin: 24px;
    min-height: 90vh;

    .unauthorized-text {
      font-family: $font-family-normal;
      font-size: 14px;
      text-align: center;
      color: darkred;
      border: 1px solid darkred;
      border-radius: 6px;
      padding: 24px;
      margin: auto;
      width: 80%;
      background-color: rgb(255, 245, 245);
    }

    .normal-text {
      font-family: $font-family-normal;
      font-size: 14px;
      color: black;
    }

    .kyc-header {
      flex-direction: column;
      width: 100%;
      padding: 24px 36px 36px 36px;
      gap: 12px;

      .title-row {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 12px;
        border-bottom: 1px solid $xpektor-light-gray;
        gap: 12px;

        .logo {
          max-width: 140px;
          margin-bottom: 6px;
        }

        h1 {
          font-family: $font-family-normal;
          font-size: 24px;
          font-weight: 600;
          color: black;
          margin-bottom: 6px;
        }
      }

      .step-display {
        flex-direction: column;
        align-items: center;

        p {
          margin-bottom: 9px;
        }

        .step-lights {
          flex-direction: row;
          justify-content: space-between;
          width: 80px;

          .step-light {
            height: 9px;
            aspect-ratio: 1;
            border-radius: 4.5px;
            background-color: $xpektor-light-gray;
            transition: background-color 0.5s;
          }

          .active-light {
            background-color: $xpektor-blue;
          }
        }
      }
    }

    .kyc-content {
      padding: 36px 36px 0 36px;
      flex-direction: column;
      border-top: 1px solid $xpektor-light-gray;
      flex-grow: 1;

      .form-type-select {
        flex-direction: column;
        gap: 12px;

        align-items: center;

        button {
          border: 1px solid $xpektor-light-gray;
          background-color: white;
          color: rgba(0, 0, 0, 0.5);
          border-radius: 6px;
          height: 48px;
          max-width: 400px;
          width: 100%;

          &:hover {
            background-color: $xpektor-light-blue;
          }
        }

        .active-button {
          color: $xpektor-blue;
          border-color: $xpektor-blue;
          background-color: $xpektor-light-blue;
        }
      }

      .small-title {
        font-size: 14px;
        color: black;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .medium-title {
        font-size: 16px;
        color: black;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .form-section {
        flex-direction: column;
        margin: 0;
        border-bottom: 1px solid $xpektor-light-gray;
        padding: 24px 0;
        gap: 24px;

        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }

        .empty-list-text {
          color: gray;
          font-style: italic;
          font-size: 14px;
        }

        .modify-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;
          margin-right: auto;
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          background: none;
          padding: 6px 12px;

          &:hover {
            border-color: $xpektor-blue;
          }

          p {
            color: $xpektor-blue;
            font-size: 14px;
            margin: 0;
          }

          .icon {
            color: $xpektor-blue;
            font-size: 12px;
          }

          &:disabled {
            opacity: 0;
          }
        }

        .remove-button {
          display: flex;
          height: 34.6px;
          margin-left: auto;
          margin-bottom: 2px;
          align-self: center;
          width: 100%;
          &:hover {
            border-color: darkred;
          }

          p {
            color: darkred;
            font-size: 14px;
            margin: 0;
            white-space: nowrap;
          }

          .icon {
            color: darkred;
          }
        }
      }

      .form-row {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        gap: 24px;
      }

      .tight-form-row {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;
        gap: 12px;
      }

      .gap-row {
        flex-direction: row;
        gap: 24px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .beneficiary-row {
        display: grid;
        grid-template-columns: 30% 25% 10% 15% 1fr;
        column-gap: 12px;
        width: 100%;
      }

      .subject-row {
        display: grid;
        grid-template-columns: 30% 35% 35%;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 24px;
        height: 90px;
        border-bottom: 1px solid $xpektor-light-gray;

        &:first-child {
          border-top: 1px solid $xpektor-light-gray;
        }
      }

      .list-entry {
        flex-direction: column;
        width: 100%;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
        margin-bottom: 12px;
        padding: 12px;

        .form-text-area-container {
          label {
            font-size: 12px;
          }
        }
      }

      .list-row {
        display: grid;
        grid-template-columns: 85% 1fr;
        column-gap: 12px;
      }

      .influential-person-row {
        display: grid;
        grid-template-columns: 35% 30% 15% 1fr;
        column-gap: 12px;
        width: 100%;
      }

      .influential-company-row {
        display: grid;
        grid-template-columns: 35% 30% 15%;
        column-gap: 12px;
        width: 100%;
      }

      .radio-select {
        width: 100%;
        flex-direction: column;

        .choice-column {
          flex-direction: column;
          gap: 12px;
        }

        .choice-row {
          flex-direction: row;
          align-items: center;
          gap: 24px;
        }
      }

      .form-column {
        flex-direction: column;
        width: 100%;
      }

      .form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .form-submitted-content {
      flex-direction: column;
      align-items: center;
      .icon {
        font-size: 36px;
        margin-bottom: 24px;
        color: green;
      }
    }

    .nav-footer {
      flex-direction: column;
      padding: 0 36px 36px 36px;
      gap: 6px;
      .info-row {
        flex-direction: row;
        height: 36px;
        align-items: center;

        .submission-error {
          color: rgb(180, 0, 0);
          margin: 0 auto;
          margin-bottom: 0;
          border: 1px solid rgb(180, 0, 0);
          background-color: rgb(255, 246, 246);
          border-radius: 6px;
          padding: 6px 24px;
        }
      }
      .button-row {
        margin-top: auto;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .kyc-modal {
      margin: 0;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .kyc-modal {
      .kyc-header {
        padding: 12px 24px;

        .title-row {
          flex-direction: column-reverse;
          align-items: center;
        }
      }
      .kyc-content {
        padding: 24px;
      }
      .nav-footer {
        padding: 0 24px 36px 24px;
      }
    }
  }
}
