@import '../../../../variables';

.auth-link-modal {
  .modal-header {
    font-family: $font-family-head;
    margin: 0 12px;
  }
  .modal-body {
    padding: 12px 24px;

    .auth-link-modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 16px;
      .xpektor-button {
        width: 140px;

        .delete-link-button {
          background-color: red;

          &:hover {
            background-color: rgb(255, 118, 118);
          }

          .loading-icon {
            color: white;
            font-size: 24px;

            animation: rotation 0.75s infinite linear;
          }
        }
      }
      .copied-check-icon {
        font-size: 16px;
      }
    }
  }
}
