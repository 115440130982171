@import '../../../../variables';

.company-table {
  width: 100%;
  margin-bottom: 32px;
  table-layout: auto;

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    box-shadow: none;

    .grade-column {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 8%;
      margin-right: auto;
    }

    .name-column {
      width: 25%;
      color: var(--Black, #16192c);
      font-weight: 600;
    }

    .connection-column {
      display: flex;
      width: 10%;
      justify-content: center;
      text-align: center;

      .connection-icon {
        font-size: 16px;
      }
    }

    th {
      .sortable {
        border: 0;
        background-color: white;
      }
    }
  }

  thead {
    display: flex;
    align-items: center;
    height: 72px;
    width: 100%;
    box-shadow: none;
    background: white;

    // sticky stuff
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 10vh;

    th {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--Grey-dark, #425466);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      background: white;
      padding: 0;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .company-item-wrapper {
      align-items: center;
      min-height: 72px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 1px solid $xpektor-light-gray;
      border-left: 4px solid $xpektor-light-gray;
      margin-bottom: 8px;
      transition: 0.125s;

      &:hover {
        box-shadow: 2px 2px 6px rgb(192, 232, 255);
        border-left-color: $xpektor-blue;
        transform: scale(1.0075);
        cursor: pointer;
      }

      td {
        padding: 0;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $font-family-normal;
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
      }

      .grade-icon {
        padding: 3px 16px;
        margin: 0;
        border-radius: 6px;
        border: 1px solid $xpektor-light-gray;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      .grade-icon-disconnected {
        border-width: 2px;
        border-style: dashed;
      }

      .grade-A {
        background-color: #c3e9c4;
        color: #28572a;
        border-color: #28572a;
      }

      .grade-B {
        background-color: rgb(225, 255, 191);
        color: #479346;
        border-color: #479346;
      }

      .grade-C {
        background-color: rgb(255, 253, 231);
        color: #f4d644;
        border-color: #f4d644;
      }

      .grade-D {
        background-color: rgb(255, 234, 195);
        color: #e5904a;
        border-color: #e5904a;
      }

      .grade-E {
        background-color: rgb(255, 208, 208);
        color: #e14844;
        border-color: #e14844;
      }

      .grade-- {
        background-color: rgb(233, 233, 233);
        color: rgb(109, 109, 109);
        border-color: rgb(109, 109, 109);
      }

      .connected {
        color: #479346;
      }
      .not-connected {
        color: #e14844;
      }
      .was-connected {
        color: #e5904a;
      }
    }
  }
}
