@import '../../../variables';

.create-auth-link-modal {
  .modal-header {
    font-family: $font-family-head;
    margin: 0 12px;
  }

  .auth-link-modal-content {
    padding: 12px;

    p {
      color: black;
      font-family: $font-family-normal;
      margin: 0;
      padding: 0;
    }

    .auth-link-description {
      margin-bottom: 32px;
      font-size: 14px;
    }

    .xpektor-button {
      z-index: 1;
    }

    .xpektor-form {
      .xpektor-form-submit-button {
        height: 40px;
        width: 160px;
        align-self: center;
        font-size: 14px;
      }
    }

    .company-info-display {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $xpektor-light-blue;
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;
      margin-top: 36px;
      padding: 12px 12px;
      text-align: center;
      p {
        font-family: $font-family-normal;
        color: black;
        padding: 0;
        margin: 0;
      }
      .company-name {
        font-size: 18px;
        font-family: $font-family-head;
      }
      .company-org-number {
        font-size: 14px;
      }
    }

    .company-info-error {
      background-color: rgb(255, 227, 227) !important;
      p {
        font-size: 16px !important;
        color: red !important;
      }
    }

    .auth-link-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 24px 0;

      .xpektor-button {
        width: 160px;
        button {
          font-family: $font-family-head;
          font-weight: 600;
        }
      }
      .auth-link-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 24px;
        width: 100%;
        height: 74px;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid $xpektor-light-gray;

        .xpektor-button {
          width: 140px;
          height: 40px;

          button {
            &:disabled {
              background-color: rgb(131, 168, 255);
            }
          }
        }

        .copied-check-icon {
          font-size: 16px;
        }
      }
    }
  }
}
