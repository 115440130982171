@import '../../../variables';

.connect-accounting-modal {
  .modal-dialog {
    max-width: 600px;

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        padding: 0;
        margin: 0;
      }

      .connect-title {
        font-family: $font-family-head;
        color: black;
        font-size: 20px;
        text-align: center;
        margin-bottom: 24px;
      }
      .connect-description {
        font-family: $font-family-normal;
        color: black;
        font-size: 14px;
        max-width: 400px;
        text-align: center;
      }
      .auth-step-help-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        margin-top: 6px;
        border: none;
        background: none;

        &:hover {
          p {
            color: rgb(142, 174, 243);
          }
          .auth-step-help-link-icon {
            color: rgb(142, 174, 243);
          }
        }
        p {
          font-size: 14px;
          color: rgba(134, 146, 166, 1);
          text-decoration: underline;
        }

        .auth-step-help-link-icon {
          color: rgba(134, 146, 166, 1);
        }
      }
      .integration-options {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
        margin: 24px 0 48px 0;
        max-width: 800px;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
