@import '../../variables';

.xpektor-searchbar {
  display: flex;
  position: relative;
  width: 30%;
  min-width: 256px;
  max-width: 400px;
  height: 36px;

  .search-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    label {
      display: none;
    }

    .input-icon {
      padding-left: 15px;
      position: absolute;
      left: 0;
      color: $xpektor-blue;
    }

    input {
      display: flex;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      border: 1px solid
        var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
      padding-left: 45px;
      font-size: 14px;
    }
  }
}
