@import '../../../../variables';

.module-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  margin-top: 10px;
  transition: opacity 0.5s;

  h2 {
    font-size: 36px;
    font-family: $font-family-normal;
    font-weight: 600;
    margin-bottom: 6px;
    color: black;
    line-height: 1;
  }

  .key-figures-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 24px;
    margin-bottom: 24px;
    width: 100%;

    .key-figure-container {
      display: flex;
      width: 100%;
      height: 114px;
      border-radius: 12px;
      border: 1px solid $xpektor-light-gray;

      .key-figure-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        padding-left: 10%;
        padding-top: 21px;

        p {
          font-family: $font-family-normal;
          padding: 0;
          margin: 0;
        }

        .key-figure-title {
          margin-right: auto;
          color: var(--747-a-80, #747a80);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        .key-figure-subtitle {
          color: var(--747-a-80, #747a80);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }

        .key-figure-value {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;
          padding: 0;

          .value {
            color: var(--000000-primary, #000);
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            margin-right: 8px;
          }

          .value-sign {
            color: var(--747-a-80, #747a80);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
  }

  .bank-account-overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    gap: 12px;
    margin-bottom: 24px;
    p {
      font-family: $font-family-normal;
      margin: 0;
      font-size: 14px;
      line-height: 1.2;
    }
    .title-row {
      display: grid;
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      .account-number {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin: auto 0;
      }
    }
    .content-row {
      display: grid;
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      border: 1px solid $xpektor-light-gray;
      border-radius: 6px;

      .entry-column {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border-right: 1px solid $xpektor-light-gray;
        gap: 2px;

        &:last-child {
          border: none;
        }

        .entry-title {
          color: rgba(0, 0, 0, 0.6);
        }
        .value {
          font-size: 14px;
          font-weight: 600;
        }
        .value-currency-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;

          .currency {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  .data-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    margin-bottom: 24px;

    .data-column {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  .kyc-card {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 400px;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    margin-bottom: 24px;

    p {
      color: black;
      margin: 0;
      font-size: 14px;
      font-family: $font-family-normal;
    }

    .main-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      gap: 24px;

      .status-sign {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid $system-dark;
        border-radius: 6px;
        padding: 6px 12px;
        gap: 12px;
        background-color: $xpektor-light-blue;
      }

      .signing-status {
        background-color: rgb(255, 252, 238);
        border-color: rgb(236, 166, 15);

        .status-text {
          color: rgb(236, 166, 15);
        }
      }

      .signed-status {
        background-color: rgb(240, 255, 240);
        border-color: darkgreen;

        .status-text {
          color: darkgreen;
        }
      }

      .data-title {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }

      .entry-column {
        flex-direction: column;
        justify-content: flex-end;
        padding: 0;
        border-bottom: 1px solid $xpektor-light-gray;
      }

      .title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        h2 {
          margin-bottom: 0;
        }
      }

      .empty-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        padding: 12px 24px;
        border: 1px solid $xpektor-light-gray;
        border-radius: 6px;
      }

      .data-column {
        display: flex;
        flex-direction: column;
        border-left: 2px solid $xpektor-light-gray;
        padding: 0 24px;
        gap: 12px;
        width: 336px;
      }
      .action-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .price-text {
          width: 400px;
          font-size: 12px;
        }
      }
    }

    .trigger-column {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $xpektor-light-gray;
      overflow-y: hidden;

      .trigger-column-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 6px;
        padding: 12px 24px 0 24px;
      }

      .trigger-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 6px;
        flex: 1;
        overflow-y: scroll;
        padding: 12px 24px;

        .trigger-container {
          display: flex;
          flex-direction: column;
          padding: 6px;
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          background-color: $xpektor-light-blue;
          gap: 2px;

          .trigger-title {
            font-weight: 600;
            font-size: 12px;
          }
          .trigger-text {
            font-size: 12px;
          }
        }

        .no-triggers-sign {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 24px 96px;
          margin: auto 0;
          gap: 6px;
          border: 1px solid $xpektor-light-gray;
          border-radius: 6px;
          background-color: rgb(244, 255, 244);

          .icon {
            color: green;
            font-size: 18px;
          }
          p {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .reports-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reports-section-vertical {
    display: flex !important;
    flex-direction: column;
    gap: 50px !important;
    margin: 0 100px !important;
    .report-wrapper {
      margin: 10px 0;
    }
  }

  .module-empty-text {
    font-family: $font-family-head;
    font-size: 18px;
    font-weight: 600;
    margin: auto auto;
    padding: 0 50px;
    color: black;
    border-bottom: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      $xpektor-blue,
      rgba(0, 0, 0, 0)
    );
  }

  .nav-bar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
  }

  .section-title {
    font-family: $font-family-normal;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid $xpektor-light-gray;
    margin: 0;
  }

  .transaction-content {
    display: grid;
    grid-template-columns: 20% 50% 1fr;
    gap: 24px;
    margin: 6px 0;
    height: 100%;

    .datapanel-wrapper {
      height: 600px;

      .datapanel-title-container {
        height: 52px;
      }
    }

    .data-column {
      display: flex;
      flex-direction: column;
      height: 600px;
      gap: 12px;

      .balance-bar {
        height: 256px;
      }
    }
  }
}
