@import '../../variables';

.icon-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-bottom: 2px solid $xpektor-light-gray;
  background: none;
  width: 150px;
  height: 100px;
  transition: 0.075s;

  &:hover {
    transform: scale(1.025);
    background-image: linear-gradient(white, $xpektor-mid-blue);
  }

  .icon-button-icon {
    font-size: 32px;
    padding: 12px 0;
    color: rgb(163, 200, 255);
  }
  p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: normal;
    color: black;
    font-weight: 500;
  }
}

.icon-button-active {
  // box-shadow: 0 0 12px 0px rgb(177, 226, 245);
  border-color: $xpektor-dark-blue;

  .icon-button-icon {
    color: $xpektor-dark-blue;
  }
}
