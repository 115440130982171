@import '../../variables';

.xpektor-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: $background-blue;

  div {
    display: flex;
  }

  h1 {
    font-size: 88px;
    line-height: 1;
    font-weight: 600;
    font-family: $font-family-normal;
    color: white;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 48px;
    line-height: 1;
    font-weight: 500;
    font-family: $font-family-normal;
    color: white;
    margin: 0;
  }

  h3 {
    font-size: 32px;
    line-height: 1;
    font-family: $font-family-normal;
    color: $xpektor-cyan;
    margin: 0;
  }

  .section-text {
    color: white;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    margin: 0;
  }

  .section-title {
    color: $xpektor-cyan;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }

  .section-with-element {
    width: 100%;
    justify-content: center;
  }

  .section {
    flex-direction: column;
    padding: 0 48px;
    width: 100%;
    z-index: 2;
  }

  .landing-intro {
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-image: url('../../Images/Home/landing_intro_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    z-index: 3;

    .intro-section {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10vh 0 auto 0;
      gap: 48px;
      padding: 0 20%;
      width: 100%;

      h1 {
        text-align: center;
      }

      .section-text {
        line-height: 24px;
        text-align: center;
        max-width: 550px;
      }
    }
  }

  .other-intro {
    flex-direction: column;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    z-index: 3;

    .intro-section {
      flex-direction: column;
      padding-top: 48px;
      padding-bottom: 92px;
      .section-title {
        font-size: 18px;
      }
      h1 {
        width: 40%;
        margin-bottom: 36px;
      }
      .button-row {
        flex-direction: row;
        gap: 24px;
      }
    }
  }

  .finance-intro {
    background-image: url('../../Images/Home/finance_intro_bg.png');
  }

  .advice-intro {
    background-image: url('../../Images/Home/advice_intro_bg.png');
  }

  .about-intro {
    background-image: url('../../Images/Home/about_intro_bg.png');
    background-position: top;

    .intro-section {
      h1 {
        width: 100%;
      }
    }
  }

  .contact-intro {
    background-image: url('../../Images/Home/about_intro_bg.png');
    background-position: top;

    .intro-section {
      h1 {
        width: 70%;
        word-wrap: break-word;
      }
    }
  }

  .why-intro {
    background-image: url('../../Images/Home/about_intro_bg.png');
    background-position: top;

    .intro-section {
      .section-text {
        width: 60%;
      }
    }
  }

  .intro-row-section {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 48px;
    width: 100%;
    gap: 24px;
    padding-top: 92px;
    padding-bottom: 128px;
    z-index: 2;

    h3 {
      max-width: 500px;
      line-height: 42px;
    }

    p {
      font-family: $font-family-normal;
      font-size: 18px;
      max-width: 800px;
      line-height: 32px;
    }
  }

  .finance-intro-row {
    h3 {
      color: $background-blue;
    }
    p {
      color: $background-blue;
    }
  }

  .advice-intro-row {
    h3 {
      display: flex;
      color: $xpektor-cyan;
      max-width: 900px;
    }
    p {
      color: white;
    }
  }

  .landing-2 {
    margin: 180px 10%;

    h2 {
      margin-bottom: 48px;
      width: 550px;
    }

    .section-text {
      font-size: 18px;
      line-height: 30px;
      width: 750px;
    }
  }

  .video-section {
    width: 100%;
    margin-bottom: 100px;
    align-items: center;

    video {
      max-height: 90vh;
      max-width: 100%;
      aspect-ratio: 2/1;
      border-radius: 32px;
      object-fit: cover;
    }
  }

  .location-section {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 128px 0 128px 0;
    padding: 0 48px;
    gap: 24px;
    .text-column {
      flex-direction: column;
      h3 {
        margin-bottom: 24px;
      }
    }
    img {
      max-height: 90vh;
      max-width: 75%;
      object-fit: cover;
      object-position: center;
      border-radius: 32px;
    }
  }

  .home-4 {
    align-items: center;
    margin-bottom: 128px;

    h2 {
      max-width: 600px;
      text-align: center;
      margin-bottom: 64px;
    }
    .row-content {
      flex-direction: row;
      justify-content: center;
      gap: 24px;
      width: 80%;
    }
  }

  .post-intro-section {
    h1 {
      width: 50%;
      min-width: 800px;
      color: $xpektor-cyan;
      padding: 64px 0;
    }
    .post-intro-content {
      display: flex;

      .image-column {
        align-items: flex-end;
        position: relative;
        width: 50%;

        img {
          margin-bottom: auto;
          width: 100%;
        }
      }
      .text-column {
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding: 0 96px 48px 96px;
        h3 {
          margin-bottom: 24px;
          line-height: 42px;
        }
        .section-text {
          margin-bottom: 24px;
          line-height: 26px;
        }
      }
    }
  }

  .logo-section {
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 192px;
    .section-title {
      margin-top: 180px;
    }
    .logo-row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 48px;
      width: 80%;
      gap: 48px;
      img {
        object-fit: contain;
        object-position: center;
        height: 36px;
      }
    }

    .carousel-root {
      width: 80%;

      .quote-container {
        flex-direction: column;
        padding: 0 10%;
        gap: 6px;
        width: 100%;

        .section-text {
          display: flex;
          width: 100%;
        }

        .author {
          font-family: $font-family-normal;
          font-size: 14px;
          font-weight: 600;
          color: white;
          margin-left: auto;
          margin-bottom: 48px;
        }
      }
    }
  }

  .banner-section {
    width: 100%;

    .background {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 64px 0;
      margin: 64px 0;
      border-radius: 12px;
      background-image: url('../../Images/Home/home_banner_background.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      h2 {
        margin-bottom: 24px;
        width: 600px;
      }

      .section-text {
        width: 550px;
        margin-bottom: 64px;
      }
    }
  }

  .founder-section {
    margin: 64px 0;
    h2 {
      width: 1000px;
      margin-bottom: 96px;
    }
    .founder-content {
      flex-direction: row;
      gap: 48px;
      img {
        width: 50%;
        height: 400px;
        object-fit: cover;
        object-position: top;
        border-radius: 12px;
      }
      .section-text {
        margin-left: auto;
        width: 50%;
        font-size: 18px;
      }
    }
  }

  .email-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    width: 100%;

    .email-box {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      flex-direction: column;
      align-items: flex-start;
      padding: 48px 15%;
      gap: 12px;

      .title-row {
        flex-direction: row;
        align-items: center;
        gap: 12px;

        img {
          height: 20px;
          aspect-ratio: 1;
        }

        .email-title {
          font-size: 20px;
          font-family: $font-family-normal;
          color: white;
          margin: 0;
        }
      }

      .email-address {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
        font-family: $font-family-normal;
        text-decoration: none;
        margin: 0;

        &:hover {
          color: $xpektor-cyan;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .message-section {
    justify-content: space-between;
    padding: 64px 0;
    margin-bottom: 64px;
    h3 {
      width: 400px;
    }
    .xpektor-form {
      align-items: flex-start;
      width: 50%;
      label {
        color: rgba(255, 255, 255, 0.7);
      }
      input {
        color: white;
      }
      textarea {
        color: white;
      }

      .form-input-container,
      .form-text-area-container {
        width: 100%;
        input {
          border: 1px solid rgba(255, 255, 255, 0.3);
        }
        textarea {
          border: 1px solid rgba(255, 255, 255, 0.3);
        }
      }
      .input-focus {
        label {
          color: white;
        }
        input,
        textarea {
          background-color: rgba(0, 0, 0, 0.25);
        }
      }

      .xpektor-form-submit-button {
        border-radius: 24px;
        background-color: $xpektor-cyan;
        color: black;

        &:hover {
          background-color: white;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 66px;
    }
    h2 {
      font-size: 36px;
    }

    .home-4 {
      .row-content {
        width: 90%;
      }
    }
    .post-intro-section {
      h1 {
        width: 100%;
        min-width: 0;
      }
    }

    .spider-web-wrapper {
      .section-text {
        width: 80%;
      }
    }

    .founder-section {
      h2 {
        width: 100%;
        margin-bottom: 96px;
      }
      .founder-content {
        flex-direction: column;
        gap: 48px;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: top;
          border-radius: 12px;
        }
        .section-text {
          width: 100%;
        }
      }
    }

    .location-section {
      flex-direction: column;

      img {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .landing-intro {
      .intro-content {
        padding: 0 10%;
      }
    }

    .landing-2 {
      h2 {
        width: 100%;
      }

      .section-text {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
      }
    }

    .home-4 {
      .row-content {
        flex-direction: column;
        align-items: center;
      }
    }

    .logo-section {
      .carousel-root {
        width: 100%;
      }
    }

    .location-section {
      padding: 0;
      .text-column {
        padding: 0 48px;
      }
      img {
        border-radius: 0;
      }
    }

    .intro-row-section {
      flex-direction: column;
    }

    .post-intro-section {
      h1 {
        display: none;
      }
      .post-intro-content {
        flex-direction: column;
        .image-column {
          align-items: center;
          width: 100%;
          padding-top: 48px;
        }
        .text-column {
          width: 100%;
          padding: 48px 0;
        }
      }
    }

    .spider-web-wrapper {
      .section-text {
        width: 90%;
      }
    }

    .video-section {
      padding: 0;
      video {
        border-radius: 0;
      }
    }

    .banner-section {
      .background {
        h2 {
          width: 90%;
        }

        .section-text {
          width: 90%;
        }
      }
    }

    .message-section {
      flex-direction: column;
      gap: 48px;

      .xpektor-form {
        width: 100%;
      }
    }

    .email-section {
      display: flex;
      flex-direction: column;

      .email-box {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        align-items: center;

        &:last-child {
          border: none;
        }
      }
    }

    .why-intro {
      .intro-section {
        .section-text {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .section {
      padding: 0 24px;
    }
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 32px;
    }

    .other-intro {
      .intro-section {
        .button-row {
          flex-direction: column;
        }
      }
    }

    .intro-row-section {
      padding: 48px 24px;
    }

    .spider-web-wrapper {
      .section-text {
        width: 100%;
      }
    }

    .video-section {
      padding: 0;
    }

    .location-section {
      padding: 0;
      .text-column {
        padding: 0 24px;
      }
    }

    .banner-section {
      .background {
        h2 {
          font-size: 26px;
        }
      }
    }

    .message-section {
      h3 {
        width: 100%;
      }
    }
  }
}
