@import '../../variables';

.radio-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  border: none;
  background: none;

  .outer-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $system-dark;
    height: 20px;
    aspect-ratio: 1;
    border-radius: 10px;
    background: none;
    padding: 0;
    .selected-icon {
      height: 10px;
      aspect-ratio: 1;
      background-color: $system-dark;
      border-radius: 5px;
    }
  }
  p {
    font-size: 14px;
    color: $system-dark;
    font-family: $font-family-normal;
    margin: 0;
    line-height: 1.2;
  }
}

.checkbox-button {
  .outer-circle {
    border-radius: 4px;

    .selected-icon {
      height: 100%;
      border-radius: 0;
      color: white;
      background-color: $system-dark;
    }
  }
}
