@import '../../variables';

.onboarding-icon-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-family: $font-family-normal;
  font-size: 14px;
  color: $xpektor-blue;
  background: none;
  border: 1px solid $xpektor-light-gray;
  border-radius: 6px;
  margin: 0;
  padding: 9px 12px;

  &:hover {
    border-color: $xpektor-blue;
  }
}
