@import '../../../variables';

.app-footer-wrapper {
  display: flex;
  height: 9vh;
  min-height: 72px;
  width: 100%;
  justify-content: center;
  border-top: 1px solid
    var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
  margin-top: auto;

  .footer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85%;

    p {
      font-size: 12px;
      font-family: $font-family-normal;
      padding: 0;
      margin: auto;
    }

    .link {
      color: black;
      font-style: normal;
      font-weight: 700;
      margin-right: 20px;
      font-family: $font-family-normal;
      font-size: 12px;
      text-decoration: none;

      &:hover {
        color: $xpektor-blue;
      }

      .hover-info-tooltip {
        .contact-container {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .trademark {
      p {
        color: var(--747-a-80, #747a80);
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
