@import '../../variables';

.service-status-display {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  gap: 6px;
  border: 1px solid $xpektor-light-gray;
  border-radius: 6px;

  &:hover {
    border-color: $xpektor-blue;
    cursor: default;
  }

  .service-status-title {
    margin: 0;
    padding: 0;
    font-family: $font-family-head;
    font-size: 16px;
    color: #e14844;
  }

  .service-status-icon {
    color: #e14844;
    font-size: 18px;
  }

  margin: 0;
  margin-left: auto;
  text-align: center;
  padding: 6px 6px;
  font-family: $font-family-normal;

  .service-status-tooltip {
    position: absolute;
    display: none;
    top: 100%;
    right: -100%;
    width: 400px;
    flex-direction: column;
    background-image: linear-gradient(135deg, $xpektor-light-blue, white);
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    padding: 12px;

    .service-status-tooltip-message {
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-bottom: 1px solid $xpektor-light-gray;
      padding-bottom: 6px;
      margin-bottom: 6px;
      p {
        padding: 0;
        margin: 0;
        font-family: $font-family-normal;
        color: black;
      }
      .tooltip-message-title-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        .tooltip-message-icon {
          font-size: 14px;
        }
        p {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .tooltip-message-text {
        font-size: 12px;
        text-align: left;
      }

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin: 0;
      }
    }
  }

  .service-status-tooltip-open {
    display: flex;
  }
  .ok {
    color: green;
  }

  .warning {
    color: #e5904a;
  }

  .error {
    color: red;
  }
}
