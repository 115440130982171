@import '../../../variables';

.spider-web-wrapper {
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 96px 0 256px 0;

  h2 {
    text-align: center;
    margin-bottom: 24px;
    width: 600px;
  }

  .section-text {
    text-align: center;
    width: 850px;
    margin-bottom: 48px;
  }

  img {
    width: 80%;
    margin-left: 0.5%;
  }

  @media screen and (max-width: 1200px) {
    img {
      width: 90%;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 900px) {
    img {
      width: 100%;
      margin-top: 48px;
    }

    h2 {
      width: 90%;
    }
  }

  @media screen and (max-width: 450px) {
    h2 {
      width: 100%;
    }
  }
}
