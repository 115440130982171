@import '../../variables';

.xpektor-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.144);
  z-index: 100;
  p {
    color: black;
    font-family: $font-family-normal;
    margin: 0;
    padding: 0;
  }

  .modal-content-window {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(173, 216, 230, 0.5),
      0 6px 20px 0 rgba(173, 216, 230, 0.4);

    .xpektor-modal-header {
      display: flex;
      width: 60%;

      justify-content: center;
      align-items: center;
      border-bottom: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        $xpektor-blue,
        rgba(0, 0, 0, 0)
      );
      p {
        font-size: 18px;
        font-family: $font-family-head;
        margin-bottom: -20px;
      }
    }

    .xpektor-modal-body {
      display: flex;
      width: 60%;
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@media screen and (max-height: 844px) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 98vh;
      width: 100%;
      .xpektor-modal-body {
        width: 95%;
      }
      .xpektor-modal-header {
        width: 95%;
      }
    }
  }
}

@media screen and (max-width: 600px) and (min-height: 845px) {
  .xpektor-modal {
    .modal-content-window {
      min-height: 98vh;
      height: auto;
      width: 95%;
      .xpektor-modal-body {
        height: auto;
        width: 95%;
      }
      .xpektor-modal-header {
        width: 95%;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 98vh;
      width: 100%;
      .xpektor-modal-body {
        width: 95%;
      }
      .xpektor-modal-header {
        width: 95%;
        // margin-top: auto;
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-height: 940px) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 98vh;
      width: 100%;
      .xpektor-modal-body {
        width: 95%;
      }
      .xpektor-modal-header {
        width: 95%;
      }
    }
  }
}
@media screen and (min-width: 768px) and (orientation: portait) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 98vh;
      .xpektor-modal-body {
        width: 60%;
      }
      .xpektor-modal-header {
        width: 60%;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 98vh;
      .xpektor-modal-body {
        width: 60%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .xpektor-modal {
    .modal-content-window {
      height: auto;
      min-height: 96vh;
      .xpektor-modal-body {
        width: 60%;
      }
    }
  }
}
