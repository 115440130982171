@import '../../variables';

.auth-step-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  p {
    font-size: 14px;
  }

  .auth-step-icon-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .auth-step-icon-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      aspect-ratio: 1;
      border: 1px solid lightgray;
      border-radius: 50%;

      .auth-step-icon {
        width: 100%;
        height: 100%;
      }

      .auth-step-icon-dot {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        background-color: lightgray;
      }

      .auth-step-icon-text {
        position: absolute;
        margin-bottom: -48px;
        color: gray;
        font-weight: 500;
      }
    }

    .auth-step-selected {
      border-color: $xpektor-blue !important;

      .auth-step-icon-dot {
        background-color: $xpektor-blue;
      }
    }

    .auth-step-line {
      border-bottom: 1px solid lightgray;
      width: 128px;
      min-width: 70px;
    }

    .auth-step-line-active {
      border-color: $xpektor-blue;
    }
  }
}
