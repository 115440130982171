@import '../../../variables';

.navbar-wrapper {
  display: flex;
  justify-content: center;
  height: 9vh;
  min-height: 72px;
  min-width: 800px;
  width: 100%;
  left: 224px;
  background-color: white;
  border-bottom: 1px solid
    var(--cfdbd-5-outline-on-light, rgba(207, 219, 213, 0.7));
  z-index: 2;

  // sticky stuff
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 86%;

    .user-name {
      display: flex;
      margin-left: auto;
      height: 60%;
      align-items: center;

      p {
        color: $font-black;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $font-family-head;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin: auto;
        padding: 0;
      }
    }
  }
}
