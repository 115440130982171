@import '../../../../variables';

.auth-link-table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid $xpektor-light-gray;
  border-radius: 12px;
  border-collapse: separate;

  thead {
    border-bottom: 1px solid $xpektor-light-gray;
    tr {
      padding-left: 28px;
      padding-right: 42px;
      th {
        font-family: $font-family-normal;
        font-size: 12px;
        font-weight: 600;
        width: 12.5%;
      }
    }
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
  }

  .sortable {
    border: 0;
    background-color: white;
  }

  tbody {
    display: flex;
    flex-direction: column;
    height: 556px;
    overflow-y: scroll;
    padding: 0 6px;
    tr {
      display: flex;
      border: 1px solid $xpektor-light-gray;
      border-radius: 12px;
      margin: 3px 0;
      height: 72px;
      min-height: 72px;
      align-items: center;
      transition: 0.125s;

      &:hover {
        box-shadow: 2px 2px 8px lightblue;
        border-color: white;
        transform: scale(1.0075);
        cursor: pointer;
      }

      td {
        overflow-x: hidden;
        overflow-y: hidden;
        font-family: $font-family-normal;
        font-size: 14px;
        width: 12.5%;
      }

      .green-icon {
        color: green;
      }

      .red-icon {
        color: red;
      }
    }
  }

  .icon-column {
    text-align: center;
  }
}
