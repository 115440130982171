@import '../../../variables';

.graph-modal-wrapper {
  .modal-dialog {
    max-width: 1000px;
    p {
      margin: 0;
      padding: 0;
      color: black;
      font-family: $font-family-normal;
    }

    .modal-title {
      margin-left: 12px;
      font-size: 20px;
    }

    .graph-modal-header {
      height: 36px;
      width: 100%;

      p {
        text-align: center;
        font-size: 12px;
        font-style: italic;
      }
    }

    .graph-modal-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 12px 12px 12px;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        background: none;
        padding: 6px;
        gap: 6px;

        .toggle-icon {
          color: $xpektor-light-gray;
          font-size: 12px;
        }

        p {
          font-size: 12px;
        }

        &:hover {
          background-color: $xpektor-light-blue;
        }
      }
      .graph-select-column {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 200px;
        margin-right: 20px;

        .graph-legend-button {
          width: 100%;
          border-left: 2px solid $xpektor-light-gray;
        }
      }
      .graph-display-column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-left: 1px solid $xpektor-light-gray;

        .graph-controls-row {
          display: flex;
          flex-direction: row;
          padding: 0 12px 6px 12px;

          .graph-control-button {
            border: 1px solid $xpektor-light-gray;
            border-radius: 6px;
          }
        }

        .graph-display {
          display: flex;
          flex-grow: 1;
          height: 400px;

          .graph-no-content {
            margin: auto;
            font-size: 16px;
          }
        }
      }
      .toggle-active {
        border-color: $xpektor-blue;

        .toggle-icon {
          color: $xpektor-blue;
        }
      }
    }
  }
}
