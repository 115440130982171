$xpektor-cyan: #b7f4ff;
$xpektor-blue: #3e66fb;
$xpektor-dark-blue: #182b72;
$system-dark: #3b4256;

$background-bright-blue: #0316f3;
$background-blue: #000b4f;
$background-dark-blue: #010024;

$xpektor-mid-blue: rgb(227, 238, 255);
$xpektor-light-blue: #f5f9fe;
$xpektor-light-gray: rgba(207, 219, 213, 0.7);

$font-family-head: 'Syne', sans-serif;
$font-family-normal: 'Inter', sans-serif;
$font-black: #16192c;

$background-gradient: linear-gradient(
  rgba(62, 102, 251, 0.2),
  rgba(158, 178, 253, 0.2),
  rgba(207, 217, 254, 0.2),
  rgba(255, 255, 255, 0.2)
);
