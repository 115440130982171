@import '../../variables';

.xpektor-slider-container {
  display: flex;
  flex-direction: row;
  transition: left 0.5s;
  position: relative;

  .slide-container {
    width: 100%;
    transition: opacity 0.5s;
  }

  .slide-hidden {
    opacity: 0;
  }
}
