@import '../../../variables';

.login-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: $background-dark-blue;

  div {
    display: flex;
  }

  .image-column {
    justify-content: flex-start;
    align-items: flex-end;
    flex-grow: 1;
    background-image: url('../../../Images/Home/xpektor_windows.png');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 12px;
    border-radius: 12px;

    .xpektor-clickable-logo {
      margin: 24px;
    }
  }

  .login-column {
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 100%;
    min-height: 600px;

    .login-header {
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      margin-bottom: 64px;

      p {
        padding: 0;
        margin: 0;

        font-family: $font-family-normal;
      }

      .login-title {
        font-size: 36px;
        text-align: center;
        color: white;
      }
      .forgot-title {
        font-size: 32px;
        color: white;
      }
      .sub-title {
        font-size: 14px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 6px;
      }
    }

    .login-form-container {
      flex-direction: column;
      align-items: center;
      height: 40%;
      min-width: 250px;
      width: 60%;

      .xpektor-form {
        .xpektor-form-input-container {
          height: 100px;
        }
        .xpektor-form-submit-button {
          margin-top: 24px;
          width: 100%;
        }
      }

      .forgot-password-description {
        padding: 0;
        margin-bottom: 24px;
        font-family: $font-family-normal;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }

      .forgot-password-button {
        margin-top: 48px;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        background: 0;
        border: 0;
        z-index: 2;

        p {
          color: white;
          font-family: $font-family-normal;
          font-weight: 600;
          font-size: 12px;
          padding: 0;
          margin: 0;

          &:hover {
            color: $xpektor-blue;
          }
        }
      }
    }

    .login-footer-container {
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;

      .contact {
        padding-bottom: 20px;

        p {
          font-family: $font-family-normal;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }

        a {
          color: white;
          text-decoration: none;
          font-weight: 500;

          &:hover {
            color: $xpektor-blue;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .login-column {
      .login-form-container {
        max-width: 400px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .login-column {
      width: 100%;
    }
    .image-column {
      display: none;
    }
  }
}
