@import '../../../variables';

.help-offer-wrapper {
  margin: 96px 0;
  padding: 0 48px;
  width: 100%;
  z-index: 2;

  .image-column {
    flex-direction: column;
    align-items: flex-end;
    padding: 0 24px;
    width: 50%;
    img {
      border-radius: 24px;
      max-width: 100%;
    }
  }

  .content-column {
    width: 50%;
    flex-direction: column;
    justify-content: center;
    padding: 0 48px;

    h2 {
      width: 60%;
      margin-bottom: 42px;
    }

    .point-column {
      flex-direction: column;
      margin-bottom: 42px;
      gap: 16px;

      .point-container {
        flex-direction: column;
        gap: 16px;
        .divider {
          height: 1px;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.1);
          width: 65%;
        }

        .point-row {
          align-items: center;
          gap: 16px;
          .point-image {
            background-image: url('../../../Images/Home/home_check_circle.svg');
            background-size: contain;
            background-repeat: no-repeat;
            width: 48px;
            min-width: 48px;
            aspect-ratio: 1;
          }

          p {
            color: white;
            font-size: 16px;
            font-weight: 200;
            margin: 0;
          }
        }
      }
    }

    .rounded-button {
      margin-right: auto;
    }
    .rounded-button-fill {
      background-color: $xpektor-blue;
      color: white;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 24px;

    .content-column {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: 900px) {
    .image-column {
      display: none;
    }

    .content-column {
      align-items: center;
      width: 100%;

      .point-column {
        .divider {
          width: 100%;
        }
      }

      h2 {
        text-align: center;
        width: 75%;
      }
      .rounded-button {
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .content-column {
      h2 {
        width: 100%;
      }
    }
  }
}
