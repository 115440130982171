@import '../../variables';

.xpektor-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  .drop-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid $xpektor-light-gray;
    border-radius: 6px;
    padding: 9px 6px;

    &:hover {
      background-image: linear-gradient(white, $xpektor-light-blue);
      border-color: $xpektor-blue;
    }

    .title {
      font-family: $font-family-normal;
      font-size: 14px;
      font-weight: 600;
      color: black;
      margin: 0 8px;
      padding: 0;
    }

    .drop-icon {
      margin-left: auto;
      font-size: 10px;
    }
  }

  .dropdown-content {
    position: absolute;
    display: none;
    flex-direction: column;
    top: 100%;
    min-width: 100%;
    background-color: white;
    box-shadow: 6px 6px 6px rgb(175, 196, 208);
    border: 1px solid $xpektor-light-gray;
    border-radius: 8px;
    z-index: 5;

    .dropdown-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      font-size: 14px;
      font-family: $font-family-normal;
      color: black;
      margin: 0;
      padding: 9px 16px;
      border-bottom: 1px solid $xpektor-light-gray;

      &:last-child {
        border: none;
      }

      .account-item {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
          font-family: $font-family-normal;
          margin: 0;
        }

        .account-title {
          font-size: 12px;
          font-weight: 600;
        }

        .account-id {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.7);
        }

        .account-balance-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;
          margin-top: 6px;

          .account-sum {
            font-weight: 600;
            font-size: 12px;
          }

          .account-currency {
            font-size: 12px;
          }
        }
      }

      &:hover {
        background-color: $xpektor-light-blue;
      }

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }
  }

  .align-right {
    right: 0;
  }

  .open {
    display: flex;
  }
}
