@import '../../../variables';

.xpektor-home {
  .policy-intro {
    flex-direction: column;
    align-items: flex-start;
    background-color: $background-bright-blue;

    .intro-section {
      flex-direction: column;
      padding: 48px 128px 92px 128px;
      width: 100%;

      .section-title {
        font-size: 18px;
      }

      h1 {
        color: $xpektor-cyan;
        margin-bottom: 36px;
      }
    }
  }

  .policy-wrapper {
    flex-direction: column;
    padding: 128px 128px;

    p {
      font-family: $font-family-normal;
      font-size: 16px;
      margin-bottom: 12px;
      color: rgba(0, 0, 0, 0.7);
    }
    li {
      font-family: $font-family-normal;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }

    .policy-header {
      flex-direction: column;
      margin-bottom: 24px;

      .policy-title {
        font-size: 30px;
        font-weight: 600;
        color: black;
        margin-bottom: 6px;
      }
      .policy-description {
        margin-top: 36px;
      }
    }

    .policy-section {
      flex-direction: column;
      margin-bottom: 24px;
      .section-title {
        font-size: 20px;
        font-weight: 600;
        color: black;
      }
      .section-sub-title {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }
    }

    .toc-section {
      flex-direction: column;
      padding: 36px 0;
      margin-bottom: 36px;
      gap: 2px;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      a {
        color: $background-bright-blue;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .policy-intro {
      .intro-section {
        padding: 48px 48px 92px 48px;

        h1 {
          word-wrap: break-word;
        }
      }
    }
    .policy-wrapper {
      padding: 128px 48px;
    }
  }

  @media screen and (max-width: 450px) {
    .policy-intro {
      .intro-section {
        padding: 48px 24px 92px 24px;

        h1 {
          word-wrap: break-word;
        }
      }
    }
    .policy-wrapper {
      padding: 128px 24px;
    }
  }
}
