@import '../../../../variables';

.delete-client-modal {
  .modal-title {
    font-family: $font-family-normal;
    font-size: 20px;
    padding: 0 6px;
  }
  .modal-body {
    padding: 12px 24px 24px 24px;
    p {
      font-family: $font-family-normal;
      font-size: 14px;
      color: black;
    }
  }
}
