.loading-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 300px;
  height: 120px;
  z-index: 1000;
}
.loading-absolute {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-relative {
  margin: auto auto;
}

.loading-circle-container {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 70%;
}

.loading-circle {
  width: 65px;
  height: 65px;
  margin: auto;
  background-image: url('../../Images/LoadingWindow/loading_circle_b.png');
  background-size: contain;
  animation: rotation 1s infinite linear;
}

.status-text {
  margin: auto;
  padding: 0;
  color: #6c757d;
  font-size: 15px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
