@import '../../variables';

.tab-nav-wrapper {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 10px;
  background-color: $xpektor-light-blue;

  .tab-nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 6px;

    p {
      margin: 0;
      padding: 0;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-family-normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .active-tab {
      background-color: $xpektor-dark-blue;

      p {
        color: white;
      }
    }

    button {
      width: 142px;
      height: 100%;
      background: 0;
      border: 0;
      border-radius: 6px;

      p {
        color: black;
      }
    }
  }
}
