@import '../../../variables';

.not-found {
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../../../Images/Home/blue_curtain.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .not-found-content {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex-grow: 1;
    padding: 24px 48px;

    h1 {
      color: $xpektor-cyan;
    }

    .title {
      width: 55%;
      margin-bottom: 48px;
    }

    .not-found-sign {
      font-size: 128px;
      margin-left: auto;
      margin-top: auto;
    }
  }

  @media screen and (max-width: 900px) {
    .title {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 450px) {
    .not-found-content {
      padding: 24px;

      .not-found-sign {
        font-size: 96px;
      }
    }
  }
}
