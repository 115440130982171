@import '../../../../variables';

.service-status-settings-container {
  .service-status-settings-nav {
    padding: 0 6px;
    .xpektor-button {
      width: 140px;
      height: 40px;
    }
  }
}
