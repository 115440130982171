@import '../../variables';

.integration-partner-button-disabled {
  .text-header {
    color: gray !important;
  }
  .text-content {
    color: darkred !important;
  }
}

.card-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: solid 1px rgba(132, 146, 166, 0.3);
  background-color: white;
  border-radius: 6px;
  padding: 36px 24px;
  transition: height 0.125s;

  .main-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .partner-logo {
      width: 140px;
      min-width: 140px;
      height: 36px;
      object-fit: contain;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $xpektor-light-gray;
      margin-left: 16px;
      padding-left: 16px;
      p {
        text-align: left;
        color: black;
      }

      .text-header {
        font-family: $font-family-normal;
        font-size: 16px;
        font-weight: 600;
      }
      .text-content {
        font-family: $font-family-normal;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
    }
    .icon {
      color: $xpektor-blue;
      visibility: hidden;
      min-width: 15px;
      margin-left: auto;
    }

    @media only screen and (max-width: 600px) {
      .integration-partner-button-content {
        padding: 15px;
        .partner-logo {
          width: 90px;
          height: auto;
        }
      }
    }
  }

  .expanded-content {
    display: flex;
    flex-direction: column;
    margin-left: 174px;

    a {
      font-size: 14px;
      font-family: $font-family-normal;
    }

    .xpektor-form {
      margin-top: 24px;
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;

      .xpektor-form-input-container {
        margin: 0;
      }
      .xpektor-form-submit-button {
        margin-top: 4px;
        height: 40px;
        width: 120px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 24px;

    .main-content-row {
      .partner-logo {
        width: 90px;
        min-width: 90px;
      }
    }

    .expanded-content {
      margin: 0;
      .xpektor-form {
        flex-direction: column;
        gap: 0;
      }
    }
  }
}

.card-button-hover {
  background-color: $xpektor-light-blue;
  border: solid 1px rgb(98, 153, 255);

  .icon {
    visibility: visible;
  }
}

.card-button-expanded {
  border: solid 1px rgb(98, 153, 255);

  &:hover {
    cursor: default;
  }
}
