@import '../../../variables';

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 1500px;
  padding-top: 32px;

  .dashboard-nav-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;

    .dash-add-client {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      gap: 10px;
      height: 60px;

      .xpektor-button {
        width: 100%;
        width: 200px;
      }
    }
  }

  .dash-table {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
