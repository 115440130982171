@import '../../../../variables';

.home-footer {
  flex-direction: column;
  margin: 48px;

  p {
    font-size: 16px;
  }

  a {
    font-weight: 200;
    text-decoration: none;
  }

  .main-row {
    flex-direction: row;
    padding: 48px 0;
    gap: 24px;
    border-top: 1px solid $xpektor-light-gray;
    border-bottom: 1px solid $xpektor-light-gray;

    .xpektor-column {
      flex-direction: column;
      gap: 12px;
      margin-right: auto;
      p {
        font-weight: 200;
        color: rgba(0, 0, 0, 0.7);
        width: 400px;
        line-height: 1.8;
      }
    }

    .link-columns {
      flex-direction: row;
      gap: 48px;
      .link-column {
        flex-direction: column;
        gap: 12px;
        .column-title {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.7);
        }
        a {
          color: rgba(0, 0, 0, 0.7);

          &:hover {
            color: $xpektor-blue;
          }
        }
      }
    }
  }

  .end-row {
    flex-direction: row;
    align-items: center;
    margin-top: 48px;
    gap: 64px;
    width: 100%;

    .interactable-row {
      flex-direction: row;
      gap: 64px;

      .country-select {
        align-items: center;
        gap: 12px;
      }

      .links {
        gap: 24px;
        align-items: center;
        a {
          color: rgba(0, 0, 0, 0.7);

          &:hover {
            color: $xpektor-blue;
          }
        }
      }
    }

    .social-media {
      flex-direction: row;
      margin-left: auto;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: 0;
        img {
          height: 24px;
          aspect-ratio: 1;
          filter: invert(1);
        }
      }
    }

    p {
      margin: 0;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 200;
    }
  }

  @media screen and (max-width: 900px) {
    .main-row {
      flex-direction: column;

      .link-columns {
        gap: 24px;
      }
    }
    .end-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .interactable-row {
        width: 100%;
        gap: 12px;
        .links {
          margin-left: auto;
        }
      }
      .social-media {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .main-row {
      .xpektor-column {
        p {
          width: 100%;
        }
      }
    }
    .end-row {
      .interactable-row {
        .links {
          gap: 12px;
        }
      }
    }
  }
}

.home-footer-dark {
  .main-row {
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);

    .xpektor-column {
      p {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .link-columns {
      .link-column {
        .column-title {
          color: white;
        }
        a {
          color: white;

          &:hover {
            color: $xpektor-cyan;
          }
        }
      }
    }
  }

  .end-row {
    .interactable-row {
      .links {
        a {
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            color: white;
          }
        }
      }
    }

    .social-media {
      button {
        img {
          filter: none;
        }
      }
    }

    p {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
