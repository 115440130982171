@import '../../../variables';

.xpektor-home {
  .why-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin: 128px auto 0 auto;

    .why-row-text-column {
      display: flex;
      flex-direction: column;
      gap: 36px;
    }

    .why-row-image {
      margin: 0 64px;
    }
  }

  .why-connections-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 64px;
    margin: 92px 48px;
    .why-connection-column {
      flex-direction: column;
      h2 {
        margin-bottom: 36px;
      }
      .why-column-image {
        width: 128px;
        aspect-ratio: 1;
        margin-bottom: 24px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .why-row {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    .why-row {
      img {
        display: none;
        margin: 0 32px;
      }
    }
    .why-connections-row {
      display: flex;
      flex-direction: column;
      margin: 48px 0;
    }
  }
}
