@import '../../../variables';

.admin-settings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;

  .admin-settings-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 76px;
    width: 100%;
    margin-top: 16px;

    .admin-settings-title {
      color: black;
      font-family: $font-family-normal;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $xpektor-light-gray;
      width: 100%;
    }
  }

  .admin-nav-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
}
