@import '../../../../variables';

.auth-link-settings-container {
  margin: 24px 0;

  .auth-link-settings-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
    .auth-link-stats {
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding: 0 12px;

      p {
        margin: 0;
        padding: 0;
        font-family: $font-family-normal;
        font-size: 14px;
        color: var(--747-a-80, #747a80);
      }
    }
    .xpektor-button {
      width: 100%;
      width: 160px;
    }
  }
}
