@import '../../../variables';

.change-password-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;

  .change-password-head {
    display: flex;
    justify-content: flex-start;
    height: 76px;
    width: 100%;
    margin-top: 18px;

    .change-password-title {
      color: var(--Black, #16192c);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $font-family-normal;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 0;
      margin: 0 0 auto 0;
    }
  }
  .change-pw-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    .change-pw-form-container {
      display: flex;
      margin-top: 20px;
      width: max(40%, 300px);
      height: 360px;
      margin-bottom: 40px;
    }
  }
}
